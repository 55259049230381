import {useSearchParams} from "react-router-dom";
import {APIurl} from "../utils/Utils";
import {useEffect, useState} from "react";


export function Prenota() {

    const [searchParams, setSearchParams] = useSearchParams();
    const company = searchParams.get("company")

    useEffect(() => {
        fetchBookingUrl().then(() => {})
    }, []);

    async function fetchBookingUrl(): Promise<void> {
        try {

            const response = await fetch(`${APIurl}/getBookingLink/${company}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            })
            if (response.ok) {
                const data = await response.json()
                window.location.href = "https://" + data.bookingUrl
            }
        } catch (error) {
            throw error;
        }
    }

    return (
        <div />
    )

}
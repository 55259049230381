import './App.css';
import {useEffect} from "react";
import {Router} from "./utils/Router";

function App() {

    useEffect(() => {
        console.info('\x1b[34m%s\x1b[0m', // \x1b[38;5;33m
            "\n\n" +
            "   ___   ___ _____ ___  ___ _   _ ___ \n" +
            "  / _ \\ / __|_   _/ _ \\| _ \\ | | / __|\n" +
            " | (_) | (__  | || (_) |  _/ |_| \\__ \\\n" +
            "  \\___/ \\___| |_| \\___/|_|  \\___/|___/\n\n" +
            "         Developed with <3 by\n" +
            "        DANIELE DI GREGORIO .it\n")
    }, []);

    return (
        <div>
            <Router />
        </div>
    );
}

export default App;

import {useEffect} from "react";


export function Redirect() {

    useEffect(() => {
        window.location.replace("https://www.octopusdesk.it")
    }, []);

    return <div/>
}
import {useSearchParams} from "react-router-dom";
import {APIurl} from "../utils/Utils";
import {useEffect, useState} from "react";


export function Unsubscribe() {

    const [searchParams, setSearchParams] = useSearchParams();
    const user = searchParams.get("user")
    const company = searchParams.get("company")

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any | null>(null);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        unsubscribe().then(() => {})
    }, []);

    return (
        <div style={{height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
            <div>
                {
                    loading ?
                        <p>Caricamento...</p>
                        :
                        success ?
                            <p>Non riceverai più mail</p>
                            :
                            error ?
                                <p>Si è verificato un errore. Riprova più tardi.</p>
                                :
                                <p>Si è verificato un errore. Riprova più tardi.</p>
                }
            </div>
        </div>
    )

    async function unsubscribe() {
        setLoading(true)
        try {

            const response = await fetch(`${APIurl}/unsubscribe/${company}/${user}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                if(response.status === 200) setSuccess(true)
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {Redirect} from "../pages/Redirect";
import {Unsubscribe} from "../pages/Unsubscribe";
import {Booking} from "../pages/Booking";
import {Prenota} from "../pages/Prenota";

export function Router() {


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/redirect" />} />
                <Route path="/redirect" element={<Redirect />} />

                <Route path="/unsubscribe" element={<Unsubscribe />} />
                <Route path="/booking" element={<Booking />} />
                <Route path="/prenota" element={<Prenota />} />

                <Route path="*" element={<Navigate to="/redirect" />} />
            </Routes>
        </BrowserRouter>
    )
}